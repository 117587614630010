/* Let's get this party started */
::-webkit-scrollbar {
    width: 0px;
}

ion-icon {
    --ionicon-stroke-width: 32px;
  }


  html,
body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 0;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.flex-no-shrink {
  flex-shrink: 0;
}